@import '~@cx/couturier/styles/settings';

.hide-for-mobile {
  display: none;
  @include breakpoint(600px) {
    display: inherit;
  }
}

.show-for-mobile {
  display: inherit;
  @include breakpoint(600px) {
    display: none !important;
  }
}

.ui-button--disabled.ui-button--working {
  background-color: $color-system-cta-active-blue;
}
//5.875rem
.clickable {
  cursor: pointer;
}

.ui-modal-overlay.ui-show {
  z-index: $z-index-1000-immersive + $z-index-600-modal;
}

.silver-dollar {
  color: $color-neutral-silver-dollar;
}

.primary-color {
  color: $color-primary-nm-blue;
}
// Overrides

label.ui-text-field {
  @include breakpoint(small) {
    margin-bottom: 1rem;
  }
}
